/* eslint-disable @typescript-eslint/no-explicit-any */

interface necessaryInfoActionI {
    type : 'syncNecessaryInfo' | 'setConnector' | 'setBasicInfo' | 'user_id',
    state : any
}

export const necessaryInfo = (state = {}, action: necessaryInfoActionI) : any => {
    switch ( action.type ) {
        case 'syncNecessaryInfo' : return {
            ...state,
            ...action.state
        };
        case 'user_id' : return {
            ...state,
            ...action.state
        };
        case 'setConnector' : return {
            ...state,
            ...action.state
        };
        case 'setBasicInfo' : return {
            ...state,
            ...action.state
        };
        default : return {...state };
    }
};